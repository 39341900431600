import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/Seo';

const post = { seo: {
  title:'404: Not found',
} };

function NotFoundPage() {
  return (
    <Layout>
      <Seo post={post} />
      <div
        className="text-center pb-16"
        style={{
          fontFamily: `Open Sans bold,Arial,sans-serif`,
        }}
      >
        <h1
          style={{
            background: `-webkit-linear-gradient(90deg,#2e3c4b,#64375b 60%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: `transparent`,
            fontSize: `210px`,
            lineHeight: `210px`,
          }}
        >
          404
        </h1>
        <h2 className="font-bold mb-8" style={{ color: `#313a46` }}>
          Page Could Not Be Found
        </h2>
        <p style={{ marginBottom: `11px` }}>
          Oops, it looks like there was an issue. Let&apos;s get you back to the
          homepage.
        </p>
        <Link
          className="btn btn-primary w-auto my-8"
          style={{ borderColor: `#f0a437`, lineHeight: `22px` }}
          to="/"
        >
          Back to the Homepage
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
